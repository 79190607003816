/* make the customizations */

/* $spacer: 1rem; */

/* Extra large buttons for use on home page */
$btn-padding-y-lg:            2rem;
$btn-padding-x-lg:            2rem;
$btn-font-size-lg:            2rem;
$btn-line-height-lg:          2rem;
$btn-border-radius-lg:        1.2rem;

.hidden { display:none; }

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 35px;
}

.alert-modal {
  z-index: 10;
  position: fixed;
  width: 300px;
  bottom: -67vh;
  right: -62vw;
}

.object {
  position: relative;
  height: 80px;
  width: 80px;
  overflow-x: hidden;
}

.ball {
  border-radius: 40px;
}

.online {
  color: #40b100;
  font-size: 1em;
}

.offline {
  font-size: 1em;
}

#clientSessionDiv {
  overflow-y: hidden;
}

/* Fixes for CK Editor */

.ck-content {
  color: black;
  max-height: 320px;
}

.ck-editor__editable span {
  color: black;
}

/* Blockquote created with CK Editor */
blockquote {
  border-left: 5px solid lighten(#4e5d6c, 35%);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}
blockquote p {
  display: inline;
  font-style: italic;
}

/* Images uploaded using CK Editor */

figure.image img {
  max-width: 100%;
  height: auto;
}

figure.image-style-side img {
  max-width: 70%;
  height: auto;
}

figcaption {
  color: lighten(#4e5d6c, 35%);
  font-size: 0.8em;
  font-style: italic;
}

// Add SASS theme customizations here..

/* import bootstrap with bootswatch superhero theme to set changes */
@import "../node_modules/bootswatch/dist/superhero/variables";
/* @import "../node_modules/bootswatch/dist/solar/variables"; */
@import "../node_modules/bootstrap/scss/bootstrap";
@import '../node_modules/react-bootstrap-range-slider/dist/react-bootstrap-range-slider';
@import "../node_modules/bootswatch/dist/superhero/bootswatch";
/*@import "../node_modules/bootswatch/dist/solar/bootswatch";*/
